@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    @apply h-full;
    overflow-x: hidden
}
body {
    @apply h-full;
}
#root {
    @apply h-full;
}

.map-wrap {
    position: relative;
    width: 100%;
    height: 100vh;
  }
  
  .map {
    width: 80%;
    height: 100%;
  }

.smallmap.map-wrap {
    width: 100%;
    height: 100%;
}


.smallmap .map {
    position: absolute;
    width: 100%;
    height: 100%;
}